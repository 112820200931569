import { Spinner } from "@nextui-org/react";

export default function LoadingCommon({ title,itemName}) {
    const name = itemName ? itemName : '';

    return <>
        <div className="h-56 grid content-center mx-auto">
            {
                title?
                <Spinner label={`Đang loading ${title}`} color="primary" labelColor="primary" className="italic" size="lg" />:
                <Spinner label={`Đang cập nhật ${name}`} color="primary" labelColor="primary" className="italic" size="lg" />
            }
        </div>
    </>
}
