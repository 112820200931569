import { useRouter } from "next/router";
import { Link, Image } from "@nextui-org/react";
import LoadingCommon from "@/components/shared/loadingCommon";

import sharedService from "@/services/shared-service";
import { MASSESTIME } from '@/components/helpers/constant.js';

import PaginationCustom from "@/components/shared/pagination-custom";
import PaginationCustomAjax from "@/components/shared/pagination-custom-ajax";

export default function ChurchesBlock({churchData, isPagination, actions}) {
    const router = useRouter();
    if (churchData.total === 0) {
        return <LoadingCommon itemName={'danh sách Nhà Thờ'} />;
    }

    return <>
        <div>
            {
                churchData.value.map((church, index) => {
                    const churchName = church?.typeName + ' ' + church?.name;

                    return <>
                        <div key={index}
                            className="search-churchs--result grid grid-cols-2 lg:grid-cols-7 gap-4 py-5 bg-white mb-3 container mx-auto rounded-md">
                            <div className="result--img m-auto pl-2">
                                <Image className="flex justify-center" alt={churchName} width={150} height={150}
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    src={church.photoUrl ? sharedService.getOptimizeImage(church.photoUrl, 'small-post-view') : ""}
                                />
                            </div>

                            <div className="result--informations lg:col-span-2 leading-7 lg:hidden">
                                <p className="font-bold lg:hidden">{churchName}</p>
                                <p className="">
                                    <Link aria-label={`Điện thoại: ${church?.phoneNumber}`} href={'tel:' + church?.phoneNumber}>
                                        Điện thoại: {church?.phoneNumber}
                                    </Link>
                                </p>
                                <p className="">Dân số: {church?.population}</p>
                                <p className="">Số tín hữu: {church?.memberCount}</p>
                            </div>

                            <div className="result--address ml-5 lg:ml-0 col-span-2 leading-7">
                                <p className="font-bold hidden lg:block">
                                    <Link aria-label={churchName} href={"/nha-tho/" + church.id} className="text-black">{churchName}</Link>
                                </p>
                                <p className="">
                                    Địa chỉ: <Link aria-label={`Địa chỉ: ${church?.address}`} href={church?.addressLink}>{church?.address}</Link>
                                </p>
                                <p className="">
                                    Email: <Link aria-label={`Email: ${church?.email}`} href={'mailto:' + church?.email}>{church?.email}</Link>
                                </p>
                            </div>

                            <div
                                className="result--informations ml-5 lg:ml-0 col-span-2 leading-7 hidden lg:block">
                                <p className="">
                                    <Link aria-label={`Điện thoại: ${church?.phoneNumber}`} href={'tel:' + church?.phoneNumber}>
                                        Điện thoại: {church?.phoneNumber}
                                    </Link>
                                </p>
                                <p className="">Dân số: {church?.population}</p>
                                <p className="">Số tín hữu: {church?.memberCount}</p>
                            </div>

                            <div className="result--time-mass ml-5 lg:ml-0 col-span-2 leading-7">
                                <p className="font-bold text-red-700">Giờ lễ:</p>
                                <p className="">
                                    {MASSESTIME.AFTER_SAT.name}: <span
                                    dangerouslySetInnerHTML={{__html: `${church.masses?.chieu_thu_bay ? church.masses?.chieu_thu_bay.replaceAll(':', 'g') : ''}`}}></span>
                                </p>
                                <p className="">
                                    {MASSESTIME.SUNDAY.name}: <span
                                    dangerouslySetInnerHTML={{__html: `${church.masses?.chua_nhat ? church.masses?.chua_nhat.replaceAll(':', 'g') : ''}`}}></span>
                                </p>
                                <p className="flex">
                                    <span className="">
                                        {MASSESTIME.NORMAL.name}: <span
                                        dangerouslySetInnerHTML={{__html: `${church.masses?.ngay_thuong ? church.masses?.ngay_thuong.replaceAll(':', 'g') : ''}`}}></span>
                                    </span>
                                    <span className="text-right flex-1 mr-4 text-red-700 font-bold">
                                        <Link href={"/nha-tho/" + church.id}>XEM THÊM</Link>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </>
                })
            }

            {
                isPagination && churchData.pageData?.pageCount > 1 && <>
                    <div className="flex justify-center p-5">
                        {/* <PaginationCustom asPath={router.asPath} pageData={churchData.pageData} /> */}
                        <PaginationCustomAjax asPath={router.asPath} pageData={churchData.pageData}
                            actions={{setCurrentPage: actions.setCurrentPage}}
                        />

                    </div>
                </>
            }
        </div>
    </>
}
